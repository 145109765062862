import axios from 'axios';
import authHeader from './auth-header';

class UserService {
  getPublicContent() {
    return axios.get(
      'https://tool.mendelsbrain.com:60010/api/content/all'
    );
  }

  getUserBoard() {
    return axios.get(
      'https://tool.mendelsbrain.com:60010/api/content/user',
      { headers: authHeader() }
    );
  }

  getAdminBoard() {
    return axios.get(
      'https://tool.mendelsbrain.com:60010/api/content/admin',
      { headers: authHeader() }
    );
  }
}

export default new UserService();
